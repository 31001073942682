import React from "react"
import BtfData from "./data/belowfold.json"
import Styles from "./css/belowfold.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"

class belowFold extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
   else if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
   else if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
   else if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
      if (isSafari()) {
        const browserData = Object.assign(this.state.data, this.state.data.safari);
        this.setState({ data: browserData });
      }
      if (isIeEdge()) {
        const browserData = Object.assign(this.state.data, this.state.data.edge);
        this.setState({ data: browserData });
      }

      if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (
      <section style={{ display: this.state.display }}>
      <div className = {Styles.belowFold} >
      <div className = {Styles.middle}>
      	<h2>{this.state.data.secondHeadline}</h2>
        <div className = {Styles.middleInner}>
          <div className = {Styles.tile}>
            <img src={this.state.data.tile1}></img>
            <h4>{this.state.data.tileTitle1}</h4>
            <h5>{this.state.data.tileDescrip1}</h5>
          </div>
          <div className = {Styles.tile}>
            <img src={this.state.data.tile2}></img>
            <h4>{this.state.data.tileTitle2}</h4>
            <h5>{this.state.data.tileDescrip2}</h5>
          </div>
          <div className = {Styles.tile}>
            <img src={this.state.data.tile3}></img>
            <h4>{this.state.data.tileTitle3}</h4>
            <h5>{this.state.data.tileDescrip3}</h5>
          </div>
        </div>
      	<p dangerouslySetInnerHTML={{ __html: this.state.data.description}}></p>
          <button 
                id="button"
                onClick={() => window.triggerInstall()}
              >
                {this.state.data.mainCTA}
              </button>
              {this.props.children}

      </div>{/*end middle*/}
      <div className = {Styles.bottom}>

      		<h2 className = {Styles.headlineMid}> {this.state.data.thirdHeadline}</h2>
      	<div className = {Styles.twocolInner}>
      		<div className = {Styles.left}>
            <div className = {Styles.howto}>
              <div className = {Styles.flexWrap}>
        				<img src={this.state.data.bulletpoint}/>
                <span>
                  <h4>{this.state.data.arrowTitle1}</h4>
                  <h6>{this.state.data.arrowDescrip1}</h6>
                </span>
              </div>
              <div className = {Styles.flexWrap}>
        				<img src={this.state.data.bulletpoint}/>
                <span>
                  <h4>{this.state.data.arrowTitle2}</h4>
                  <h6>{this.state.data.arrowDescrip2}</h6>
                </span>
              </div>
              <div className = {Styles.flexWrap}>
        				<img src={this.state.data.bulletpoint}/>
                <span>
                  <h4>{this.state.data.arrowTitle3}</h4>
                  <h6>{this.state.data.arrowDescrip3}</h6>
                </span>
            </div>
      			</div>

      </div>

      		<div className = {Styles.right}>
          <img src={this.state.data.secondMedia}/>
      		</div>
      		</div>
              <button 
                id="button"
                onClick={() => window.triggerInstall()}
              >
                {this.state.data.mainCTA}
              </button>
              {this.props.children}
  </div>
      </div>
      
      {this.props.footer}
      </section>
    )
  }
}

export default belowFold
